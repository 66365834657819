import { AccountInfo, IPublicClientApplication } from "@azure/msal-browser";

const getUserPhoto = async (
  account: AccountInfo | null,
  authProvider: IPublicClientApplication
): Promise<string> => {
  try {
    if (account) {
      const token = await authProvider.acquireTokenSilent({
        scopes: ["https://graph.microsoft.com/User.Read"],
        account,
      });

      const headers = {
        Authorization: token.accessToken,
        "Content-Type": "image/jpg",
      };

      const data = await fetch(
        `https://graph.microsoft.com/v1.0/me/photos/48x48/$value`,
        {
          headers,
        }
      );

      const url = window.URL || window.webkitURL;
      const blobUrl = url.createObjectURL(await data.blob());
      return blobUrl;
    } else {
      return "";
    }
  } catch (er) {
    console.error(er);
    return "";
  }
};

export { getUserPhoto };
