import { Spinner, SpinnerSize, useTheme } from "@fluentui/react";
import React from "react";
import { useAppSelector } from "../state";

const TableLoading = ({ title }: { title: string }) => {
  const theme = useTheme();
  const appTheme = useAppSelector((x) => x.main.appTheme);

  const from =
    appTheme === "light"
      ? theme.palette.blackTranslucent40
      : theme.palette.whiteTranslucent40;

  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: `linear-gradient(to bottom,  ${from} 0%, ${theme.palette.neutralLighterAlt} 100%)`,
        zIndex: 1,
      }}
    >
      <Spinner size={SpinnerSize.medium} label={`Loading ${title}...`} />
    </div>
  );
};

export default TableLoading;
