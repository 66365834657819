import { ThemeProvider, Image, Text } from "@fluentui/react";
import {
  MsalAuthenticationTemplate,
  MsalProvider,
  useAccount,
} from "@azure/msal-react";

import {
  AccountInfo,
  AuthenticationResult,
  EventMessage,
  EventType,
  InteractionType,
  IPublicClientApplication,
} from "@azure/msal-browser";
import { authenticationParameters } from "./common/auth/MsalConfig";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import { RbacGuard } from "./common/auth/RbacGuard";
import { AccessRoles } from "./common/auth";
import { useEffect, useState } from "react";
import { getUserPhoto } from "./features/Header";
import { useAppDispatch, useAppSelector } from "./common/state";
import { Header } from "./features/Header";
import NotFound from "./NotFound";
import Home from "./features/Home/Home";
import ScanResults from "./features/ScanResults";
import Nav from "./common/components/Nav";
import Container from "./common/components/Container";
import {
  CoherenceTheme,
  CoherenceV9DarkTheme,
  CoherenceV9LightTheme,
} from "@coherence-design-system/styles";
import { setTheme } from "./common/state/main";
import ScanResultsDetails from "./features/ScanResults/ScanResultsDetails";
import useThemeDetector from "./common/hooks/useThemeDetector";
const Inner = ({
  msalInstance,
}: {
  msalInstance: IPublicClientApplication;
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const account = useAccount() as Required<AccountInfo>;
  const appTheme = useAppSelector((e) => e.main.appTheme);
  const [isNavCollapsed, setIsNavCollapsed] = useState(false);
  function onNavCollapsed(_isNavCollapsed: boolean) {
    setIsNavCollapsed(_isNavCollapsed);
  }

  return (
    <>
      <Header
        title={
          <>
            <Image
              src="./trlogo.png"
              height={50}
              alt="CDE CredScan"
              onClick={() => navigate("/")}
              style={{ cursor: "pointer" }}
            />
            <Text variant="xLarge" style={{ color: "white" }}>
              CredScan
            </Text>
          </>
        }
        logout={msalInstance.logout}
        getUserPhoto={() => getUserPhoto(account, msalInstance)}
        account={account}
        hideSignOut={true}
        additionalItems={[
          {
            title: "Theme",
            iconName: "View",
            onClick: () =>
              dispatch(setTheme(appTheme === "light" ? "dark" : "light")),
            isAdmin: false,
          },
        ]}
      />
      <Nav onNavCollapsed={onNavCollapsed} />
      <Container isNavCollapsed={isNavCollapsed}>
        <Routes>
          <Route
            path="/"
            element={<RbacGuard Element={<Home />} roles={AccessRoles} />}
          />
          <Route
            path="/scanresults"
            element={
              <RbacGuard Element={<ScanResults />} roles={AccessRoles} />
            }
          />
          <Route
            path="/scanresults/:id"
            element={
              <RbacGuard Element={<ScanResultsDetails />} roles={AccessRoles} />
            }
          />

          <Route path="*" element={<NotFound />} />
        </Routes>
      </Container>
    </>
  );
};

export const App = ({
  msalInstance,
}: {
  msalInstance: IPublicClientApplication;
}) => {
  msalInstance.handleRedirectPromise().then((result) => {
    if (result) {
      msalInstance.setActiveAccount(result.account);
    }
    return result;
  });
  const dispatch = useAppDispatch();

  const isSystemThemeDark = useThemeDetector();

  useEffect(() => {
    if (isSystemThemeDark) {
      dispatch(setTheme("dark"));
    }
  }, [isSystemThemeDark]);

  msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const payload = event.payload as AuthenticationResult;
      const account = payload.account;
      msalInstance.setActiveAccount(account);
    }
  });
  const appTheme = useAppSelector((e) => e.main.appTheme);
  const availableThemes = {
    default: { fluentV8: CoherenceTheme },
    light: { fluentV8: CoherenceV9LightTheme },
    dark: { fluentV8: CoherenceV9DarkTheme },
    lightNeutral: {
      fluentV8: { ...CoherenceV9LightTheme, neutralHeaderEnabled: true },
    },
  };
  return (
    <MsalProvider instance={msalInstance}>
      <MsalAuthenticationTemplate
        authenticationRequest={authenticationParameters}
        interactionType={InteractionType.Redirect}
      >
        <ThemeProvider theme={availableThemes[appTheme].fluentV8}>
          <BrowserRouter>
            <Inner msalInstance={msalInstance} />
          </BrowserRouter>
        </ThemeProvider>
      </MsalAuthenticationTemplate>
    </MsalProvider>
  );
};
