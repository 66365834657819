import { useState, useEffect, useCallback, ReactElement } from "react";
import {
  CoherenceHeader,
  ItemDisplayStatus,
  ItemStatus,
  NotificationItemDisplayStatus,
  NotificationItemStatus,
  NotificationListItem,
} from "@coherence-design-system/controls";
import {
  CommandBarButton,
  ICommandBarItemProps,
  TooltipHost,
  useTheme,
} from "@fluentui/react";
import { getHeaderButtonStyles } from "./styles";

type HelpPanelProps = {
  title?: string;
  content?: ReactElement;
};

type AdditionalItem = {
  title: string;
  iconName: string;
  onClick: () => void;
  isAdmin: boolean;
};

type HeaderProps = {
  title: string | ReactElement;
  getUserPhoto(): Promise<string>;
  notificationItems?: NotificationListItem[];
  helpPanelSettings?: HelpPanelProps;
  settingsPanelSettings?: HelpPanelProps;
  shouldShowAdminActions?: boolean;
  additionalItems?: AdditionalItem[];
  account?: UserAccount;
  logout(): void;
  hideSignOut?: boolean;
  onFeedBackClick?: () => boolean;
};
type UserAccount = {
  username: string;
  name: string;
};
function Header({
  title,
  getUserPhoto,
  account,
  additionalItems,
  shouldShowAdminActions,
  logout,
  hideSignOut,
  helpPanelSettings,
  notificationItems,
}: HeaderProps) {
  const [imgUrl, setImgurl] = useState<string>("");
  const [_notificationItems, setNotificationItems] = useState<
    NotificationListItem[] | undefined
  >(notificationItems);
  const [newNotifications, setNewNotifications] = useState<number>(0);

  const additionalItemsList = additionalItems
    ? additionalItems
        .filter((x) => shouldShowAdminActions || !x.isAdmin)
        .map(
          (x, i): ICommandBarItemProps => ({
            key: i.toString(),

            commandBarButtonAs: () => (
              <ToolTipContextButton
                onClick={x.onClick}
                title={x.title}
                iconName={x.iconName}
              />
            ),
          })
        )
    : undefined;

  const getUserPhotoCallback = useCallback(async () => {
    const photo = await getUserPhoto();
    if (photo) {
      setImgurl(photo);
    }
  }, [getUserPhoto]);

  useEffect(() => {
    if (account) {
      getUserPhotoCallback();
    }
  }, [account]);

  useEffect(() => {
    if (notificationItems) {
      setNotificationItems([...notificationItems]);
      const cnt = notificationItems.filter(
        (x) => x.status === NotificationItemStatus.UNREAD
      ).length;

      setNewNotifications(cnt);
    } else {
      setNotificationItems([]);
      setNewNotifications(0);
    }
  }, [notificationItems]);

  const updateStatus = () => {
    if (_notificationItems && _notificationItems.length > 0) {
      const list = _notificationItems.map((x) => ({
        ...x,
        displayStatus: NotificationItemDisplayStatus.OLD,
      }));

      setNotificationItems(list);
    }
  };

  const updateItem = (
    itemKey: string,
    dStatus: ItemDisplayStatus,
    rStatus?: ItemStatus
  ) => {
    if (_notificationItems) {
      const list = _notificationItems.map((item) => ({ ...item }));
      list.map((item) => {
        if (item.itemKey === itemKey) {
          if (item.status === NotificationItemStatus.UNREAD) {
            setNewNotifications((prevState) => prevState - 1);
          }

          item.displayStatus = dStatus;
          if (rStatus) {
            item.status = rStatus;
          }
        }
      });
      setNotificationItems(list);
    }
  };

  const headerButtonStyles = getHeaderButtonStyles(useTheme());

  // for some reason, the tooltip doesn't work with the additonalItems setting, so we have to wrap it
  function ToolTipContextButton({
    iconName,
    title,
    onClick,
  }: Pick<AdditionalItem, "iconName" | "title" | "onClick">) {
    const id = `${iconName}TooltipId`;

    return (
      <TooltipHost content={title} id={id}>
        <CommandBarButton
          aria-describedby={id}
          className={headerButtonStyles.rightItemButton}
          iconProps={{
            iconName: iconName,
            className: headerButtonStyles.rightItemButtonIcon,
          }}
          onClick={onClick}
        />
      </TooltipHost>
    );
  }

  return (
    <span id="sip-navbar">
      <CoherenceHeader
        headerLabel={"header"}
        appNameSettings={{
          label: title,
        }}
        farRightSettings={{
          notificationsSettings: {
            panelSettings: {
              items: _notificationItems,
              onPanelOpened: updateStatus,
              newNotifications: newNotifications,

              updateItem: updateItem,
            },
          },
          helpSettings: {
            panelSettings: {
              titleText:
                helpPanelSettings && helpPanelSettings.title
                  ? helpPanelSettings.title
                  : undefined,
              body:
                helpPanelSettings && helpPanelSettings.content ? (
                  helpPanelSettings.content
                ) : (
                  <></>
                ),
            },
          },
          profileSettings: {
            panelSettings: {
              fullName: account?.name,
              emailAddress: account?.username,
              imageUrl: imgUrl,
              hideSignOut: hideSignOut,
              hideSignOutDivider: hideSignOut,
              logOutLink: "#",
              onLogOut: () => logout,
            },
          },
          additionalItems: additionalItemsList,
        }}
      ></CoherenceHeader>
    </span>
  );
}

export { Header };
