import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "../../common/state/store";

export type ScanState = {
  engagement?: string;
  icmNumber?: string;
};

const initialState: ScanState = {};

const scanSlice = createSlice({
  name: "scan",
  initialState,
  reducers: {
    setScanInfo(state, action: PayloadAction<ScanState>) {
      state.engagement = action.payload.engagement;
      state.icmNumber = action.payload.icmNumber;
    },
    clear(state) {
      state = initialState;
    },
  },
});

export const { setScanInfo } = scanSlice.actions;

export const selectScanInfo = (state: RootState) => state.scan;

export default scanSlice.reducer;
