import { Stack, TextField } from "@fluentui/react";
import { FC } from "react";
import { setScanInfo } from "../Blobs/scanSlice";
import { useAppDispatch, useAppSelector } from "../../common/state";

const EngagementView: FC = () => {
  const scanInfo = useAppSelector((state) => state.scan);
  const dispatch = useAppDispatch();
  return (
    <Stack horizontalAlign="end">
      <Stack
        tokens={{ childrenGap: "10" }}
        horizontal
        horizontalAlign="end"
        verticalAlign="end"
      >
        <TextField
          label="Engagement"
          required
          value={scanInfo.engagement}
          placeholder="Enter Engagement"
          onChange={(e, newValue) => {
            dispatch(setScanInfo({ ...scanInfo, engagement: newValue || "" }));
          }}
        />
        <TextField
          label="ICM Number"
          value={scanInfo.icmNumber}
          placeholder="Enter ICM Number"
          onChange={(e, newValue) => {
            dispatch(setScanInfo({ ...scanInfo, icmNumber: newValue || "" }));
          }}
        />
      </Stack>
    </Stack>
  );
};

export default EngagementView;
