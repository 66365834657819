import { combineReducers } from "@reduxjs/toolkit";
import { resourceApi } from "../../common/api/api";
import main from "./main";
import scan from "../../features/Blobs/scanSlice";

const rootReducer = combineReducers({
  [resourceApi.reducerPath]: resourceApi.reducer,
  main: main,
  scan: scan
});
export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
