import { configureStore } from "@reduxjs/toolkit";

import rootReducer from "./rootReducer";
import { resourceApi } from "../api/api";

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(resourceApi.middleware),
});

export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type KnownError = {
  status: string;
  title: string;
  detail: string;
};
