import {
  DetailsList,
  MarqueeSelection,
  PrimaryButton,
  Stack,
  Selection,
  DetailsListLayoutMode,
  SelectionMode,
  Breadcrumb,
  Dialog,
  DialogType,
  DefaultButton,
  DialogFooter,
  useTheme,
  Text,
  Link,
  MessageBar,
  MessageBarType,
} from "@fluentui/react";
import React, { useEffect, useRef, useState } from "react";
import { RootState, useAppDispatch, useAppSelector } from "../../common/state";
import {
  useGetBlobsQuery,
  useSendBlobDetailsMutation,
} from "../AzSubscriptions";
import { setBlobPrefix } from "../../common/state/main";
import { BlobDetail, getColumns } from "./type";
import Paginator from "./Paginator";
import TableLoading from "../../common/components/TableLoading";
import { Link as RouterLink } from "react-router-dom";
import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { useSelector } from "react-redux";

type TokenPageMap = {
  [key: number]: string | undefined;
};
const Blobs = () => {
  const [, setRefresh] = useState(true);
  const [sendBlobDetails, { isLoading, isSuccess, error: scanError, reset }] =
    useSendBlobDetailsMutation();
  const dispatch = useAppDispatch();
  const accountName = useAppSelector((e) => e.main.storageAccountName);
  const scanInfo = useSelector((state: RootState) => state.scan);
  const prefix = useAppSelector((e) => e.main.blobPrefix);
  const containerName = useAppSelector((e) => e.main.containerName);
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [tokenPageMap, setTokenPageMap] = React.useState<TokenPageMap>({});
  const selectedKeys = useRef<string[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const theme = useTheme();
  const [selection] = useState(
    new Selection({
      onSelectionChanged: () => selectionChangedHandler(),
      onItemsChanged: () => itemsChangedHandler(),
    })
  );

  const selectionChangedHandler = () => {
    const previousSelectedKeys = selectedKeys.current;
    const keysInSelection = selection.getItems().map(({ key }) => key);
    const currentSelectedKeys = selection.getSelection().map(({ key }) => key);

    const newSelectedKeys = [
      ...currentSelectedKeys,
      ...previousSelectedKeys.filter(
        (
          key // keep previously selected keys if
        ) =>
          !keysInSelection.includes(key) || // not in current selection
          (keysInSelection.includes(key) && currentSelectedKeys.includes(key)) // or in current selection and is selected
      ),
    ];

    // @ts-ignore
    const newUniqueKeys = [...new Set(newSelectedKeys)];

    selectedKeys.current = newUniqueKeys;
    setRefresh((prevValue) => !prevValue);
  };

  const itemsChangedHandler = () => {
    for (const { key } of selection.getItems()) {
      selection.setKeySelected(
        key as string,
        selectedKeys.current.includes(key as string),
        false
      );
    }
  };

  const { data, error, isFetching } = useGetBlobsQuery(
    {
      accountName: accountName!,
      containerName: containerName!,
      continuationToken: tokenPageMap[currentPage],
      prefix,
    },
    {
      skip: !accountName || !containerName,
    }
  );
  const isFetchBaseQueryError = (error: any): error is FetchBaseQueryError => {
    return error && typeof error === "object" && "data" in error;
  };

  const isSerializedError = (error: any): error is SerializedError => {
    return error && typeof error === "object" && "message" in error;
  };

  useEffect(() => {
    if (error) {
      let errorMessage = "An unknown error occurred";
      if (
        isFetchBaseQueryError(error) &&
        error.data &&
        typeof error.data === "object" &&
        "message" in error.data
      ) {
        errorMessage = (error.data as { message: string }).message;
      } else if (isSerializedError(error)) {
        errorMessage = error.message || "An unknown error occurred";
      }
      setErrorMessage(errorMessage);
    }
  }, [error]);

  useEffect(() => {
    if (scanError) {
      let errorMessage = "An unknown error occurred";
      if (
        isFetchBaseQueryError(scanError) &&
        scanError.data &&
        typeof scanError.data === "object" &&
        "message" in scanError.data
      ) {
        errorMessage = (scanError.data as { message: string }).message;
      } else if (isSerializedError(error)) {
        errorMessage = error.message || "An unknown error occurred";
      }
      setErrorMessage(errorMessage);
    }
  }, [scanError]);

  useEffect(() => {
    if (isFetching) {
      setErrorMessage(undefined);
    }
  }, [isFetching]);

  useEffect(() => {
    if (data) {
      if (data.continuationToken) {
        setTokenPageMap((prev) => {
          return {
            ...prev,
            [currentPage + 1]: data.continuationToken,
          };
        });
      }
    }
  }, [data]);
  async function _sendBlobDetails() {
    const blobDetails = selection.getSelection() as BlobDetail[];
    setIsDialogVisible(true);

    await sendBlobDetails({
      blobDetails,
      engagement: scanInfo.engagement,
      icmNumber: scanInfo.icmNumber,
    });
  }

  const closeDialog = () => {
    setIsDialogVisible(false);
  };

  const validate = () => {
    if (!scanInfo.engagement) {
      return "Engagement is required";
    }
    return "";
  };
  return (
    <Stack tokens={{ childrenGap: 10 }} styles={{ root: { width: "100%" } }}>
      <Breadcrumb
        styles={{ list: { backgroundColor: theme.palette.neutralLighter } }}
        style={{ height: "25px", padding: "5px 0" }}
        items={
          prefix?.split("/")?.map((item, index) => ({
            text: item,
            key: item,
            onClick: () => {
              if (index === 0) {
                dispatch(setBlobPrefix(""));
              } else {
                dispatch(
                  setBlobPrefix(
                    prefix
                      ?.split("/")
                      .slice(0, index + 1)
                      .join("/") + "/"
                  )
                );
              }
            },
          })) || []
        }
        maxDisplayedItems={10}
        ariaLabel="Breadcrumb with items rendered as buttons"
        overflowAriaLabel="More links"
      />

      <Dialog
        hidden={!isDialogVisible}
        onDismiss={closeDialog}
        dialogContentProps={{
          type: DialogType.normal,
          title: isLoading ? "Processing" : isSuccess ? "Success" : "Error",
          // @ts-ignore
          subText: isLoading ? (
            "Please wait while the scan is processing..."
          ) : isSuccess ? (
            <Text>
              The scan was triggered successful! Check{" "}
              <Link as={RouterLink} to="/scanResults">
                Here
              </Link>{" "}
              for the results.
            </Text>
          ) : (
            "An error occurred during the scan."
          ),
        }}
        modalProps={{
          isBlocking: true,
        }}
      >
        <DialogFooter>
          {!isLoading && <DefaultButton onClick={closeDialog} text="Close" />}
        </DialogFooter>
      </Dialog>
      <Stack.Item align="center" style={{ width: "100%" }}>
        {errorMessage && (
          <MessageBar
            messageBarType={MessageBarType.error}
            isMultiline={true}
            onDismiss={() => setErrorMessage(undefined)}
            dismissButtonAriaLabel="Close"
          >
            {errorMessage}
          </MessageBar>
        )}
        <MarqueeSelection selection={selection}>
          <DetailsList
            styles={{ root: { height: "500px" } }}
            items={
              isFetching
                ? []
                : data?.blobs && containerName
                ? data?.blobs || []
                : []
            }
            columns={getColumns((name) => dispatch(setBlobPrefix(name)))}
            selection={selection}
            selectionMode={SelectionMode.multiple}
            setKey="multiple"
            getKey={(e, i) => e.id}
            selectionPreservedOnEmptyClick={true}
            layoutMode={DetailsListLayoutMode.justified}
          />
          {isFetching && <TableLoading title="blobs" />}
        </MarqueeSelection>
      </Stack.Item>
      <Stack.Item align="center">
        <PrimaryButton
          disabled={!selectedKeys.current || selectedKeys.current.length === 0}
          onClick={async () => {
            const validation = validate();
            if (validation === "") {
              await _sendBlobDetails();
            } else {
              setErrorMessage(validation);
            }
          }}
        >
          Scan
        </PrimaryButton>
      </Stack.Item>
      <Stack.Item align="center">
        <Paginator
          hasNext={!!tokenPageMap[currentPage + 1]}
          currentPage={currentPage}
          handleNext={() => setCurrentPage(currentPage + 1)}
          handlePrevious={() => setCurrentPage(currentPage - 1)}
        />
      </Stack.Item>
    </Stack>
  );
};

export default Blobs;
