import * as React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  CoherenceNav,
  INavLinkGroup,
  INavLink,
  INavStyles,
} from "@coherence-design-system/controls";
interface NavProps {
  onNavCollapsed: (isCollapsed: boolean) => void;
}
function Nav(props: NavProps) {
  const navigate = useNavigate();
  const location = useLocation();
  // Gets the url currently navigated to, lets us know which view is currently selected, if any.
  const viewUrl = location.pathname.split("/")[1];

  const navGroupLinks: INavLinkGroup[] = [
    {
      key: "CredentialScanNv",
      links: [
        {
          name: "Cred Scan",
          key: "CredentialScan",
          icon: "SecurityGroup",
          ariaLabel: "Cred Scan",

          onClick: (ev?: React.MouseEvent<HTMLElement>, item?: INavLink) => {
            if (ev) {
            }
          },
          links: [
            {
              name: "Home",
              key: "Home",
              ariaLabel: "home",
              isSelected: viewUrl ? "/" === viewUrl : false,
              onClick: (
                ev?: React.MouseEvent<HTMLElement>,
                item?: INavLink
              ) => {
                if (ev) {
                  ev.nativeEvent.preventDefault();
                  navigate("/");
                }
              },
            },
            {
              name: "Scan Results",
              key: "ScanResults",
              ariaLabel: "Scan Results",
              isSelected: viewUrl ? "scanResults" === viewUrl : false,
              onClick: (
                ev?: React.MouseEvent<HTMLElement>,
                item?: INavLink
              ) => {
                if (ev) {
                  ev.nativeEvent.preventDefault();
                  navigate("/scanResults");
                }
              },
            },
          ],
        },
      ],
    },
  ];

  const styles: Partial<INavStyles> = {
    navContainer: {},
  };

  return (
    <CoherenceNav
      appName={"Cred Scan UI"}
      groups={navGroupLinks}
      onNavCollapsed={props.onNavCollapsed}
      styles={styles}
    ></CoherenceNav>
  );
}

export default Nav;
