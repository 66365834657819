import { ActionButton, Stack } from "@fluentui/react";
import { useNavigate } from "react-router-dom";

function NotFound() {
  const navigate = useNavigate();
  return (
    <Stack
      style={{ height: "70vh" }}
      horizontal
      horizontalAlign="center"
      verticalAlign="center"
    >
      <Stack horizontalAlign="center">
        <h1>404 - Not Found!</h1>
        <ActionButton
          iconProps={{ iconName: "back" }}
          onClick={() => navigate("/")}
          allowDisabledFocus
        >
          Go Home
        </ActionButton>
      </Stack>
    </Stack>
  );
}

export default NotFound;
