import React from "react";
import { HoverCard, HoverCardType, IPlainCardProps } from "@fluentui/react";
import { Stack, TextField, Label } from "@fluentui/react";

interface JsonDisplayProps {
  jsonString: string;
}

const JsonDisplay: React.FC<JsonDisplayProps> = ({ jsonString }) => {
  let jsonObject: any;
  try {
    jsonObject = JSON.parse(jsonString);
  } catch (error) {
    return <div>Invalid JSON</div>;
  }

  const renderForm = (obj: any, level: number = 0) => {
    if (obj === null || obj === undefined) {
      return null;
    }

    return Object.keys(obj).map((key) => (
      <div key={key} style={{ marginLeft: level * 20 }}>
        {typeof obj[key] === "object" ? (
          <>
            <Label>{key}</Label>
            {renderForm(obj[key], level + 1)}
          </>
        ) : (
          <TextField
            label={key}
            value={obj[key] === null || obj[key] === undefined ? "" : obj[key]}
            readOnly
            styles={{ root: { width: "100%" } }}
          />
        )}
      </div>
    ));
  };

  const plainCardProps: IPlainCardProps = {
    onRenderPlainCard: () => (
      <Stack
        tokens={{ childrenGap: 10, padding: 10 }}
        styles={{ root: { minWidth: 500 } }}
      >
        {renderForm(jsonObject)}
      </Stack>
    ),
  };

  return (
    <HoverCard
      plainCardProps={plainCardProps}
      instantOpenOnClick
      type={HoverCardType.plain}
      styles={{ host: { minWidth: 300 } }}
    >
      <div style={{ cursor: "pointer", textDecoration: "underline" }}>
        Hover to view JSON
      </div>
    </HoverCard>
  );
};

export default JsonDisplay;
