import { mergeStyleSets, IStyle, ITheme, useTheme } from "@fluentui/react";

const rootDivStyles = (theme: ITheme) => ({
  position: "fixed",
  top: 48,
  bottom: 0,
  right: 0,
  background: theme.palette.white,
});

const _Styles = (theme: ITheme) =>
  mergeStyleSets({
    rootDivCollapsed: {
      ...rootDivStyles(theme),
      left: 48,
    } as IStyle,
    rootDivExpand: {
      ...rootDivStyles(theme),
      left: 228,
    } as IStyle,
    contentRight: {
      width: "100%",
      height: "100%",
      overflow: "auto",
    } as IStyle,
    toggle: {
      marginBottom: "30px",
    } as IStyle,
  });

interface ContainerProps {
  isNavCollapsed: boolean;
  children: any;
}

function Container(props: ContainerProps) {
  const theme = useTheme();
  return (
    <div
      className={
        props.isNavCollapsed
          ? _Styles(theme).rootDivCollapsed
          : _Styles(theme).rootDivExpand
      }
    >
      <div className={_Styles(theme).contentRight}>{props.children}</div>
    </div>
  );
}

export default Container;
