import { DefaultEffects, Stack, mergeStyles, useTheme } from "@fluentui/react";
import React from "react";

const StandardForm = ({
  children,
  widthPercent = 30,
  mediumWidthPercent = 50,
  smallWidthPercent = 80,
}: {
  children: React.ReactNode;
  widthPercent?: number;
  smallWidthPercent?: number;
  mediumWidthPercent?: number;
}) => {
  const theme = useTheme();
  const containerStyles = mergeStyles({
    width: `${widthPercent}%`,
    background: `${theme.palette.neutralLighterAlt}`,
    borderRadius: DefaultEffects.roundedCorner2,
    padding: "35px",
    boxSizing: "content-box",
    boxShadow: DefaultEffects.elevation4,
    "@media (max-width: 1500px)": {
      width: `${mediumWidthPercent}%`,
    },
    "@media (max-width: 800px)": {
      width: `${smallWidthPercent}%`,
    },
  });
  return (
    <Stack styles={{ root: { margin: "50px 0" } }} horizontalAlign="center">
      <Stack tokens={{ childrenGap: 20 }} className={containerStyles}>
        {children}
      </Stack>
    </Stack>
  );
};

export default StandardForm;
