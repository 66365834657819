import { IconButton } from "@fluentui/react";
import React from "react";

interface PaginatorProps {
  currentPage: number;
  hasNext: boolean;
  handlePrevious: () => void;
  handleNext: () => void;
}

const Paginator: React.FC<PaginatorProps> = ({
  currentPage,
  handlePrevious,
  handleNext,
  hasNext,
}) => {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <IconButton
        iconProps={{ iconName: "ChevronLeft" }}
        onClick={handlePrevious}
        disabled={currentPage === 1}
      />

      <span style={{ margin: "0 10px" }}>{currentPage}</span>
      <IconButton
        iconProps={{ iconName: "ChevronRight" }}
        disabled={!hasNext}
        onClick={handleNext}
      />
    </div>
  );
};

export default Paginator;
