import React, { useEffect } from "react";
import { useGetScanResultsQuery } from "../../common/api/api";
import {
  DetailsList,
  DetailsListLayoutMode,
  IColumn,
  SelectionMode,
  ShimmeredDetailsList,
} from "@fluentui/react";
import { columns, jobColumns } from "./types";
import { useNavigate } from "react-router-dom";

const ScanResults = () => {
  const { data } = useGetScanResultsQuery(undefined, {
    pollingInterval: 60000,
  });
  const navigate = useNavigate();
  return (
    <div>
      <ShimmeredDetailsList
        items={data || []}
        enableShimmer={data === undefined}
        columns={jobColumns(navigate)}
        setKey="set"
        layoutMode={DetailsListLayoutMode.fixedColumns}
        selectionMode={SelectionMode.none}
      />
    </div>
  );
};

export default ScanResults;
