import ReactDOM from "react-dom";
import { App } from "./App";
import { loadTheme, mergeStyles, registerIcons } from "@fluentui/react";
import store from "./common/state/store";
import { authProvider } from "./common/auth/MsalConfig";
import "./index.css";
import { createRoot } from "react-dom/client";
import {
  fluentTreeItem,
  fluentTreeView,
  provideFluentDesignSystem,
} from "@fluentui/web-components";
import { Provider } from "react-redux";
// Inject some global styles
mergeStyles({
  selectors: {
    ":global(body,html,#root)": {
      margin: 0,
      padding: 0,
      height: "100%",
      backgroundColor: "unset",
      background: "unset",
      // backgroundColor: CoherenceV9DarkTheme.palette.white,
      fontFamily: "Helvetica",
    },
  },
});

provideFluentDesignSystem().register(fluentTreeItem(), fluentTreeView());

declare global {
  namespace JSX {
    interface IntrinsicElements {
      ["fluent-tree-view"]: any;
      ["fluent-tree-item"]: any;
    }
  }
}
declare global {
  namespace JSX {
    interface IntrinsicElements {
      ["fluent-tree-view"]: any;
      ["fluent-tree-item"]: any;
    }
  }
}

const container = document.getElementById("root");
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(
  <Provider store={store}>
    {" "}
    <App msalInstance={authProvider} />
  </Provider>
);
