import { PublicClientApplication, RedirectRequest } from "@azure/msal-browser";

enum CacheStorage {
  LocalStorage = "localStorage",
  SessionStorage = "sessionStorage",
}

// Msal Configurations
const configs = {
  auth: {
    authority: process.env.REACT_APP_AUTHORITY,
    clientId: process.env.REACT_APP_CLIENT_ID,
    validateAuthority: true,
    redirectUri: "/",
  },
  cache: {
    cacheLocation: CacheStorage.LocalStorage,
    storeAuthStateInCookie: false,
  },
};

export const authenticationParameters: RedirectRequest = {
  scopes: process.env.REACT_APP_API_SCOPES.split(","),
};

export const authProvider = new PublicClientApplication(configs);

export async function getAccessToken() {
  return await authProvider.acquireTokenSilent({
    ...authenticationParameters,
    account: authProvider.getActiveAccount() || undefined,
  });
}

export function hasAnyRole(): boolean {
  const roles = authProvider.getActiveAccount()?.idTokenClaims
    ?.roles as unknown as string[];

  if (!roles) return false;

  return roles.length > 0;
}

export function getUserName(): string {
  return authProvider.getActiveAccount()?.username || "";
}

export function getUserDisplayName(): string {
  return authProvider.getActiveAccount()?.name || "";
}

export function isInRole(role: string): boolean {
  const roles = authProvider.getActiveAccount()?.idTokenClaims
    ?.roles as unknown as string[];

  if (!roles) return false;
  const multiRoles = role.split(",");
  if (multiRoles.length > 1) {
    for (const r of multiRoles) {
      if (roles.includes(r)) {
        return true;
      }
    }
  } else {
    return roles.includes(role);
  }
  return false;
}
