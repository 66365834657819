import StandardForm from "../../common/components/StandardForm";
import Blobs from "../Blobs/Blobs";
import {
  IStackStyles,
  PrimaryButton,
  Stack,
  TextField,
  useTheme,
} from "@fluentui/react";
import { useAppDispatch } from "../../common/state";
import { setAccountName } from "../../common/state/main";
import Containers from "../Containers/Containers";
import { useState } from "react";
import EngagementView from "./EngagementView";

const stackStyles: IStackStyles = {
  root: {
    flex: 1,
    overflow: "hidden",
    width: `100%`,
  },
};
const Home = () => {
  const [inputAccountName, setInputAccountName] = useState("");
  const dispatch = useAppDispatch();
  const theme = useTheme();

  const handleInputChange = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    value?: string
  ) => {
    setInputAccountName(value || "");
  };
  return (
    <div>
      <StandardForm
        widthPercent={90}
        mediumWidthPercent={90}
        smallWidthPercent={90}
      >
        <EngagementView />
        <Stack horizontal tokens={{ childrenGap: 10 }} styles={stackStyles}>
          <Stack.Item grow align="start">
            <Stack
              style={{
                marginTop: "58px",
              }}
            >
              <Stack horizontal>
                <Stack.Item grow align="start">
                  <TextField
                    styles={{
                      fieldGroup: {
                        borderRadius: "4px 0 0 4px",
                        borderColor: theme.palette.neutralLight,
                      },
                    }}
                    placeholder="Enter storage account name"
                    value={inputAccountName}
                    onChange={handleInputChange}
                  />
                </Stack.Item>
                <Stack.Item align="start">
                  <PrimaryButton
                    styles={{
                      root: { minWidth: "5px", borderRadius: "0 4px 4px 0" },
                    }}
                    iconProps={{ iconName: "Search" }}
                    onClick={() => {
                      dispatch(setAccountName(inputAccountName));
                    }}
                  ></PrimaryButton>
                </Stack.Item>
              </Stack>
              <Containers />
            </Stack>
          </Stack.Item>

          <Blobs />
        </Stack>
      </StandardForm>
    </div>
  );
};

export default Home;
