import { IColumn, IconButton } from "@fluentui/react";

export type BlobResults = {
  blobs: BlobDetail[];
  continuationToken: string;
};

export type BlobDetail = {
  accountName: string;
  name: string;
  contentType: string;
  size?: number;
  lastModified?: string;
  isDirectory?: boolean;
};

export function getColumns(setPrefix: (prefix?: string) => void): IColumn[] {
  return [
    {
      key: "name",
      name: "Name",
      fieldName: "name",
      minWidth: 200,
      maxWidth: 400,
      isResizable: true,
      onRender: (item: BlobDetail) => {
        const fileName = item.name.split("/").pop();
        const displayName = item.name.split("/").filter(Boolean).pop();
        return item.isDirectory ? (
          <>
            <IconButton
              iconProps={{ iconName: "FolderHorizontal" }}
              title={displayName}
              onClick={() => setPrefix(item.name)}
              ariaLabel="Add"
            />
            {displayName}
          </>
        ) : (
          <>
            <IconButton
              iconProps={{ iconName: "OpenFile" }}
              title={fileName}
              ariaLabel="Add"
            />
            {displayName}
          </>
        );
      },
    },
    {
      key: "contentType",
      name: "Content Type",
      fieldName: "contentType",
      minWidth: 70,
      maxWidth: 90,
      isResizable: true,
    },
    {
      key: "size",
      name: "Size",
      fieldName: "size",
      minWidth: 70,
      maxWidth: 90,
      isResizable: true,
      data: "number",
      onRender: (item: BlobDetail) => (item.size ? `${item.size} bytes` : ""),
    },
    {
      key: "lastModified",
      name: "Last Modified",
      fieldName: "lastModified",
      minWidth: 70,
      maxWidth: 90,
      isResizable: true,
      data: "date",
      onRender: (item: BlobDetail) =>
        item.lastModified && new Date(item.lastModified).toLocaleDateString(),
    },
  ];
}
