import {
  ComboBox,
  IComboBoxOption,
  Stack,
  useTheme,
  Image,
  FontIcon,
  List,
  Spinner,
  SpinnerSize,
  MessageBar,
  MessageBarType,
} from "@fluentui/react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../common/state";
import {
  setBlobPrefix,
  setContainerContiuationToken,
  setContainerName,
} from "../../common/state/main";
import { useGetContainersQuery } from "../AzSubscriptions";
import { generateStyles } from "./utils";
import { StorageContainer } from "./type";
import TableLoading from "../../common/components/TableLoading";
import styles from "./container.module.css";
import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";

const Containers = () => {
  const dispatch = useAppDispatch();
  const accountName = useAppSelector((e) => e.main.storageAccountName);
  const theme = useTheme();
  const classNames = useMemo(() => generateStyles(theme), [theme]);
  const selectedContainer = useAppSelector((e) => e.main.containerName);
  function onContainerChange(name: string) {
    dispatch(setContainerName(name));
    dispatch(setBlobPrefix(""));
  }
  const [errorMessage, setErrorMessage] = useState<string>();

  const onRenderCell = useCallback(
    (item?: StorageContainer, index?: number): JSX.Element => {
      if (!item) {
        return <></>;
      }
      return (
        <Stack>
          <Stack
            className={`${styles.container} ${
              item.name === selectedContainer ? styles.selected : ""
            }`}
            style={{
              padding: "5px",
              borderRadius: "5px",
              // @ts-ignore
              "--hover-color": theme.palette.neutralLighter,
              "--selected-color": theme.palette.whiteTranslucent40,
            }}
            onClick={() => onContainerChange(item.name)}
            tokens={{ childrenGap: 10 }}
            horizontal
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <FontIcon iconName="Favicon" style={{ fontSize: 16 }} />
            </div>
            <div className={classNames.itemName}>{item.name}</div>
          </Stack>
        </Stack>
      );
    },
    [classNames, selectedContainer, theme]
  );

  const containerContiuationToken = useAppSelector(
    (e) => e.main.containerContiuationToken
  );
  const { data, isFetching, error } = useGetContainersQuery(
    { accountName: accountName!, continuationToken: containerContiuationToken },
    {
      skip: !accountName,
    }
  );

  const isFetchBaseQueryError = (error: any): error is FetchBaseQueryError => {
    return error && typeof error === "object" && "data" in error;
  };

  const isSerializedError = (error: any): error is SerializedError => {
    return error && typeof error === "object" && "message" in error;
  };

  useEffect(() => {
    if (error) {
      let errorMessage = "An unknown error occurred";
      if (
        isFetchBaseQueryError(error) &&
        error.data &&
        typeof error.data === "object" &&
        "message" in error.data
      ) {
        errorMessage = (error.data as { message: string }).message;
      } else if (isSerializedError(error)) {
        errorMessage = error.message || "An unknown error occurred";
      }
      setErrorMessage(errorMessage);
    }
  }, [error]);

  return (
    <div
      style={{
        padding: "5px",
        height: "300px",
        width: "300px",
        position: "relative",
      }}
    >
      {errorMessage && (
        <MessageBar
          messageBarType={MessageBarType.error}
          isMultiline={true}
          onDismiss={() => setErrorMessage(undefined)}
          dismissButtonAriaLabel="Close"
        >
          {errorMessage}
        </MessageBar>
      )}
      {isFetching ? (
        <TableLoading title="containers" />
      ) : (
        data?.containers.map((item, index) => (
          <React.Fragment key={item.name}>
            {onRenderCell(item, index)}
          </React.Fragment>
        ))
      )}
    </div>
  );
};

export default Containers;
