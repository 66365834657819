import { ITheme, mergeStyleSets, getFocusStyle } from "@fluentui/react";

export const generateStyles = (theme: ITheme) => {
  const { palette, semanticColors, fonts } = theme;
  return mergeStyleSets({
    itemCell: [
      getFocusStyle(theme, { inset: -1 }),
      {
        selectors: {
          "&:hover": { background: palette.neutralLight },
        },
      },
    ],

    itemName: [
      fonts.medium,
      {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    ],
  });
};
